@import "~normalize-scss/sass/normalize";

$iconsLinkNew:          '~src/assets/icons/new';
$texturesLink:          '~src/assets/textures';
$imagesLink:            '~src/assets/images';
$iconsLink:             '~src/assets/icons';
$fontLink:              '~src/assets/font';

$fontFamily:            'Roboto', sans-serif;

$default-gray:          #707070;

$yellow:                #F3CA3E;
$yellow-hover:          #F5BE09;
$grey:                  #969696;
$border-grey:           #F0F0F0;

$bg-silver:             #fcfcfc;
$bg-gray:               #f1f1f1;
$bg-darkgray:           #e0e0e0;
$gray-text:             #3B3C3C;

$white:                 #ffffff;
$black:                 #000000;
$simple-gray:           #c1c1c1;
$country-bg:            #F3F5F8;
$back-gray:             #d4d4d4;
$light-gray:            #dedede;
$icon-active-gray:      #8b8b8b;
$icon-gray:             #cccccc;
$icon-bg-active:        #ededed;
$icon-bg:               #fbfbfb;
$icon-green:            #07b500;
$circle-gray:           #eae7e7;
$brand-color:           #16416C; // BRAND COLOR
$light-brand-color:		#f3f5f8;
$interior-color:		#00A14B;
$blue-text:             #004171;
$inactive-color:        #5c7d97;
$color-gray-default:    #c6c6c6;
$blind-item-bg:         #F3F5F8;
$brand-dark-color:      #1D426A;

$yellow-color:          #FCA500;
$placeholder-color:     #8D8D8D;
$input-bg:              #F3F5F8;
$error-color:           #BF0000;

$main-text-color:       #555555;
$text-gray:             #bcbcbc;

$global-width:          100%;
$content-width:         90%;

$toolbar-width:         470px;
$toolbar-title-size:    40px;

$frame-circle-border:   #d3d3d3;
$frame-yellow-color:    #FEC42D;
$car-premium-border:    #FFC525;
$retailer-box-shadow:   #00000029;

$light-blue:            #F8FBFF;
$bg-light-gray:         #F6F9F9;
$green:                 #166C6C;

$toggle-color-on:       $brand-color;
$toggle-color-off:      $circle-gray;
$toggle-control-color:  $white;
$toggle-width:          40px;
$toggle-height:         20px;
$toggle-gutter:         3px;
$toggle-radius:         50%;
$toggle-control-speed:  .15s;
$toggle-control-ease:   ease-in;
$toggle-radius:         $toggle-height / 2;
$toggle-control-size:   $toggle-height - ($toggle-gutter * 2);
$shadow:				2px 2px 10px rgba($black, 0.05), -2px -2px 10px rgba($black, 0.05);


